.spiner-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

a{text-decoration:none!important}

.thumb-cross-icon .Polaris-Button{position: absolute;right: 0;border: none;background-color: transparent;}
.thumb-cross-icon .Polaris-Button:hover{border: none;background-color: transparent;}
.thumb-cross-icon .Polaris-Button svg {
  fill: #FFF;
}

.search_div {
  text-align: center;
  margin-top: 25px;
  padding: 10px;
  .content_span {
    font-size: 1.5rem !important;
    margin-top: 10px;
  }
}

.merchant_list_page {
  .text_wrapper {
    margin-left: 20px;
  }
}

.Polaris-DataTable__Cell--header {
  font-weight: 700;
}
.Polaris-TextField__Spinner {
  display: none;
}
.merchant {
  overflow-x: auto;
  margin-top: 15px;
  .table {
    width: auto;
    th,
    td {
      min-width: 120px;
      white-space: nowrap;
      padding: 15px 10px;
      vertical-align: middle;
      .Polaris-TextField {
        min-width: 110px;
      }
    }
  }
}
.text_editor_wrapper {
  .editor_title {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .Polaris-Card__Subsection {
    height: 270px;
    .quill {
      height: 200px;
    }
  }
}
.variant_wrapper {
  position: relative;
  .add_button {
    position: absolute;
    left: 85%;
    margin-top: -42px;
  }
}

div.show-image {
  float: left;
  background: white;
}
div.show-image:hover img {
  opacity: 0.5;
}
div.show-image:hover .deleteBtn {
  display: block;
}
div.show-image .deleteBtn {
  position: absolute;
  display: none;
  top: 0;
  right: 0;
}
.uploadImage > :first-child:not(.image_add_box) {
  grid-column: 1 / span 2;
  grid-row: 1 / span 2;
}
.uploadImage > .image_add_box:first-child {
  grid-column: 1 / span 4;
  grid-row: 1 / span 2;
}

.uploadImage > div:not(.image_add_box) {
  position: relative;
  padding-top: 100%;
}

.uploadImage > div:not(.image_add_box) > * {
  position: absolute;
  padding: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.uploadImage > div img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
  background: #ddf;
  border: 1px solid #aaf;
}
.uploadImage {
  grid-template-columns: repeat(4, 1fr);
  display: grid;
  grid-gap: 0.8rem;
  width: 100%;
  max-height: 340px;
  overflow-y: scroll;
  img {
    max-width: 100%;
  }
}
.product_customn_field {
  margin-top: 10px;
  .customn_field {
    margin-top: 10px;
    .custumn_remove {
      margin-top: 33px;
    }
  }
}

.variantImage {
  grid-template-columns: repeat(4, 1fr);
  display: grid;
  grid-gap: 0.8rem;
  width: 100%;
  img {
    max-width: 100%;
  }
}
.variantImage > div {
  position: relative;
  padding-top: 100%;
}
.variantImage > div img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
  background: #ddf;
  border: 1px solid #aaf;
}

.variant_image_wrapper {
  height: 100%;
}

.variantImage > div > * {
  position: absolute;
  padding: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.variantImage {
  .show-image.active {
    outline: 3px solid #5f6dc8;
    border: 4px solid rgba(0, 0, 0, 0);
    &:after {
      content: "✓";
      position: absolute;
      bottom: -6%;
      right: -6%;
      background: #5f6dc5;
      padding: 2px 5px;
      color: #fff;
      border-radius: 50%;
    }
  }
}
.Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer {
  display: none;
}
.OrderHistorySection {
  .timeline {
    margin: 20px 30px;
    list-style-type: none;
    position: relative;
    > li {
      margin: 20px 0;
      padding-left: 20px;
      background: #f8f8f8;
      border-radius: 5px;
      margin-left: 5px;
      padding: 10px;
      width: fit-content;
      .title {
        font-weight: 600;
        color: #687575;
      }
      .order-date {
        color: #687575;
      }
      //Before
      &::before {
        content: " ";
        background: white;
        display: inline-block;
        position: absolute;
        border-radius: 50%;
        border: 2px solid #3f51b5 !important;
        left: 22.5px;
        width: 15px;
        height: 15px;
        z-index: 400;
      }
    }
    //Before
    &::before {
      content: " ";
      background: #d4d9df;
      display: inline-block;
      position: absolute;
      left: 29px;
      width: 2px;
      height: 100%;
      z-index: 400;
    }
  }
}
.order_details_modal {
  .OrderStatusSection {
    margin-bottom: 10px;
    margin-left: 20px;
    display: flex;
    span {
      display: flex;
      text-transform: capitalize;
      align-items: center;
      .Polaris-Icon__Svg {
        max-width: 95%;
      }
    }
    span:after {
      content: "|";
      margin: 0px 10px;
    }
    span:last-child {
      &:after {
        content: "";
        margin: 0px;
        color: #a9a9a9;
      }
    }
  }
  .Polaris-Modal-Section {
    padding: 10px;
    .Polaris-DataTable__Table tbody tr .Polaris-DataTable__Cell:nth-child(2) {
      font-weight: normal;
    }
  }
  .Polaris-DataTable__Cell {
    padding: 5px;
  }
  .order_span {
    padding: 10px;
    text-transform: capitalize;
  }
  .order_span_cost {
    padding: 10px;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 500;
  }
  .order_span_image {
    width: 17px;
    margin-left: 10px;
    margin-bottom: 3px;
  }
  .customer_toggle {
    margin-top: 20px;
  }
  .customer_details {
    font-size: 16px;
    font-weight: 500;
  }

  .p_title {
    text-transform: capitalize;
    font-weight: 500;
    width: 100%;
    display: block;
    margin-bottom: 5px;
    color: #404140;
  }
  .p_sku {
    font-weight: 500;
    color: #918f8f;
  }
  .total_summery {
    padding: 10px 0px;
  }
}
.order_list_page {
  .button_wrapper {
    margin-top: 20px;
  }
  .order_section {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.image_wrapper {
  .image_order {
    padding: 5px;
  }
}
.category_list_page {
  .manage_category_btn {
    margin-bottom: 20px;
  }
  .Polaris-Page__Content {
    @media (max-width: 767px) {
      margin-right: 10px;
    }
  }
}
.manage_script_list_page {
  .manage_script {
    margin-bottom: 20px;
  }
}
.product_list_page {
  .product_list_button {
    margin-bottom: 20px;
  }
  .Polaris-Page__Content {
    @media (max-width: 767px) {
      margin-right: 10px;
    }
  }
}

//Finance Summary
.Polaris-Stack__Item {
  a {
    font-size: 14px;
    text-decoration: none;
  }
}

//analytics
.analytics_page {
  .Polaris-Stack__Item {
    a {
      font-size: 14px;
      text-decoration: none;
    }
  }
  .Polaris-Card__Section {
    padding: 10px !important;
    span {
      font-size: 14px;
      font-weight: 400;
    }
  }
  .sales_div {
    margin-top: 15px !important;
    .title_heading {
      font-size: 10px !important;
      font-weight: 500 !important;
    }
    .sub_heading {
      margin-top: 12px !important;
      span {
        font-size: 30px !important;
      }
    }
  }
}

//Polaris Date Picker
.Polaris-DatePicker__Header {
  position: initial !important;
}

//Datatable Css
.Polaris-DataTable__Table {
  .Polaris-DataTable__Cell {
    border-bottom: 0.1rem solid var(--p-border-subdued, rgba(63, 63, 68, 0.15));
  }
  thead {
    tr {
      th {
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
  tbody {
    tr {
      &:hover {
        background: var(--p-surface-subdued, #f4f6f8);
        //cursor:pointer;
      }
      th,
      td {
        vertical-align: middle;
      }
      .Polaris-DataTable__Cell:nth-child(2) {
        font-weight: normal;
        font-size: 14px;
        white-space: normal;
      }
      th {
        img {
          height: 80px;
          width: 80px;
          box-shadow: var(
            --p-card-shadow,
            0 0 0 1px rgba(63, 63, 68, 0.05),
            0 1px 3px 0 rgba(63, 63, 68, 0.15)
          );
          padding: 10px;
        }
      }
      td {
        button {
          margin-right: 10px;
        }
      }
    }
  }
}
.paginate {
  .Polaris-Pagination {
    padding: 10px;
  }
}
.options-list {
  //Tags Input Css
  .react-tagsinput {
    background-color: white;
    border: 0.1rem solid var(--p-border, #c4cdd5);
    border-radius: var(--p-border-radius-base, 3px);
    box-shadow: inset 0 1px 0 0 rgba(99, 115, 129, 0.05);
    padding-top: 10px;
    @media (min-width: 1439px) {
      padding-top: 2px;
    }
    .react-tagsinput-tag {
      background-color: var(--p-action-secondary, #dfe3e8);
      border-radius: 3px;
      color: var(--p-text, #212b36);
      border-color: #dfe3e8;
      display: inherit;
      font-size: 1.4rem;
    }
    .react-tagsinput-input {
      color: #000;
      font-size: 1.4rem;
      margin-bottom: 4px;
    }
  }
  .react-tagsinput--focused {
    border: 0.2rem solid #5c6ac4;
    outline: none;
  }
}
.topMerchantsThrottling {
  .Polaris-EmptyState {
    margin: 0px;
  }
}

@media (min-width: 40em) {
  .Polaris-DisplayText--sizeLarge {
    font-size: 2rem;
    line-height: 2rem;
  }
}

.page-loading-section {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  top: 50%;
}

//Page Wrapper
.Polaris-Page-Header{
  padding: 1.25rem 0 !important;
  .Polaris-Header-Title{
    margin:0 !important;
  }
}
.Polaris-TextField__Suffix{
    cursor: pointer;
}
.side-icon{
  .primary_link {
    font-weight: 500;
    color: #000;
    text-decoration: none;
    cursor: pointer;
    float: left;
  }
  .primary_link:hover {
    color: #3f4eae;
    text-decoration: underline;
    cursor: pointer;
  }
  .Polaris-Icon{
    float: right;
    margin-left: 5px;
    display:none;
  }
  :hover{
    .Polaris-Icon{
      display: block;
    }
  }
}

//Pagination
.paginate{
  .Polaris-Stack{
    padding:10px;
  }
}

/*Data Table Loading*/ 
.data-wrapper{
  position: relative;
  .loading{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(255, 255, 255, 0.5);
      .Polaris-Spinner{
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
      }
  }
}

/*User Management*/
.role-permission{
  display: inline;
  .Polaris-ChoiceList__Choices li{
    display: inline;
    margin-right: 12px;
    .Polaris-Choice__Control{
      margin-right: 5px;
    }
  }
}

.Polaris-FooterHelp__Content{
  .Polaris-FooterHelp__Icon{
    display: none;
  }
}

.variant-img{
  .variant-img-btn{ 
    .Polaris-Button {
      align-items: center;
      background: var(--p-surface);
      border: 0.125rem dashed var(--p-border);
      border-radius: 5px;
      display: flex;
      height: 3.75rem;
      justify-content: center;
      width: 3.75rem;
    }
  }
  .variant-img-select{ 
    .Polaris-Button {
      border: none;
      padding: 0;
      margin:0;
    }
  }
}

.css-261qy-menu{
  z-index: 1000;
}

.Polaris-Layout__AnnotationDescription{
  p {
    font-size:0.9em !important;
  }
}


