.myproducts {
  .Polaris-Page {
    width: 100%;
    max-width: 100%;
    padding: 0 10% 0 10%;
    display: inline-block;
    .Polaris-Page-Header {
      padding-top: 2rem;
    }
  }
  .Polaris-Card {
    width: 100%;
    display: inline-block;
    .product-list {
      .image-avatar {
        padding: 20px;
        float: left;
        .thumbnail-image {
          img {
            height: 120px;
            width: 120px;
            box-shadow: var(
              --p-card-shadow,
              0 0 0 1px rgba(63, 63, 68, 0.05),
              0 1px 3px 0 rgba(63, 63, 68, 0.15)
            );
          }
        }
      }
      .product-details {
        float: left;
        padding: 20px 15px;
        .product-title {
          padding: 15px 0px;
          color: #5c6ac4;
          font-weight: 500;
          font-size: 16px;
        }
        .product-cost {
          font-size: 14px;
          padding-bottom: 15px;
          .cost {
            .cost-label {
              font-weight: 600;
            }
            .cost-price {
              padding-left: 8px;
            }
          }
        }
        .product-store-info {
          padding-bottom: 10px;
          font-weight: 500;
          font-size: 14px;
          .store-info {
            .store-name {
              font-weight: 600;
            }
            .product-price {
              padding-left: 10px;
              .price-helper {
                color: #3f51b5;
                font-weight: 500;
                font-size: small;
              }
              .price-label {
                font-weight: 500;
              }
              .price {
                padding-left: 8px;
              }
            }
          }
        }
      }

      .action {
        float: left;
        padding: 50px 20px 20px 20px;
        text-align: center;
        vertical-align: middle;
        .action-icon {
          img {
            height: 50px;
            width: 50px;
          }
        }
      }
    }
  }
}

.myproducts-list {
  .back {
    width: 100%;
    margin-top: 3em;
    font-size: 15px;
    .back-icons {
      cursor: pointer;
    }
    .Polaris-Icon {
      display: inline-block;
      margin-left: -5px;
      svg {
        fill: var(--p-icon, #637381);
      }
    }
    span {
      color: var(--p-text-subdued-on-surface, #637381);
      font-weight: 500;
      vertical-align: middle;
    }
    @media (min-width: 768px) and (max-width: 1438px) {
      padding: 0 3.2rem;
    }
  }
  @media screen and (max-width: 767px) {
    .back {
      margin-left: 1em;
    }
    .Polaris-TextField {
      margin-bottom: 15px;
    }
  }

  .Polaris-Page {
    width: 100%;
    max-width: 100%;
    display: inline-block;
    @media (min-width: 1439px) {
      padding: 0;
    }
    .Polaris-Page-Header {
      padding-top: 3.2rem;
    }
  }
  .Polaris-Card__Section {
    .paginate {
      .Polaris-Pagination {
        padding: 10px;
      }
    }
  }
  .Polaris-DataTable__Table {
    .Polaris-DataTable__Cell {
      border-bottom: 0.1rem solid
        var(--p-border-subdued, rgba(63, 63, 68, 0.15));
    }
    thead {
      tr {
        th {
          font-weight: 600;
        }
        th:nth-child(2){
          max-width: 15vw;
          min-width: 12.5rem;
        }
      }
    }
    tbody {
      tr {
        &:hover {
          background: var(--p-surface-subdued, #f4f6f8);
          //cursor:pointer;
        }
        th,
        td {
          vertical-align: middle;
        }
        .Polaris-DataTable__Cell:nth-child(2) {
          font-weight: bolder;
          font-size: 14px;
          white-space: normal;
        }
        th {
          img {
            height: 80px;
            width: 80px;
            box-shadow: var(
              --p-card-shadow,
              0 0 0 1px rgba(63, 63, 68, 0.05),
              0 1px 3px 0 rgba(63, 63, 68, 0.15)
            );
            padding: 10px;
          }
        }
        td {
          button {
            margin-right: 10px;
          }
          .less-stock {
            height: 2rem;
            background-color: rgb(252, 235, 219);
            color: rgb(192, 87, 23);
            padding: 0px 0.2rem;
            border-radius: 1.5px;
          }
        }
      }
    }
  }
}

.img-list-wrapper {
  position: relative !important;
  border: 1px solid #e1e6e5;
  background: white;
  border-radius: 0.25rem;
  width: 40px;
  height: 48px;

  img {
      bottom: 0 !important;
      left: 0 !important;
      margin: auto !important;
      height: auto !important;
      width: auto !important ;
      max-height: 100%;
      max-width: 100%;
      right: 0 !important;
      position: absolute !important;
      top: 0 !important;
      box-shadow: none !important;
      padding : 5px !important;
  }
}
