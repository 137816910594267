//Product Add
.product-container{
    .option-container{
      border-bottom: .0625rem solid var(--p-divider);
      .option-wrapper{
        margin: 0.5rem 1.25rem 0.8rem 1.25rem;
      }
    }
  }

  .img-list-wrapper {
    position: relative !important;
    border: 1px solid #e1e6e5;
    background: white;
    border-radius: 0.25rem;
    width: 40px;
    height: 48px;
  
    img {
        bottom: 0 !important;
        left: 0 !important;
        margin: auto !important;
        height: auto !important;
        width: auto !important ;
        max-height: 100%;
        max-width: 100%;
        right: 0 !important;
        position: absolute !important;
        top: 0 !important;
        box-shadow: none !important;
        padding : 5px !important;
    }
  }