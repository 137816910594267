.import-product {
  .search-filter {
    width: 100%;
    max-width: 100%;
    display: inline-block;
    margin-top: 3rem;
    .Polaris-Connected,
    .Polaris-Select {
      @media screen and (max-width: 767px) {
        margin: 0px 10px 15px 10px;
      }
    }
  }
  .back {
    width: 100%;
    margin-top: 3em;
    font-size: 15px;
    .back-icons {
      cursor: pointer;
    }
    .Polaris-Icon {
      display: inline-block;
      margin-left: -5px;
      svg {
        fill: var(--p-icon, #637381);
      }
    }
    span {
      color: var(--p-text-subdued-on-surface, #637381);
      font-weight: 500;
      vertical-align: middle;
    }
    @media (min-width: 768px) and (max-width: 1438px) {
      padding: 0 3.2rem;
    }
  }
  @media screen and (max-width: 767px) {
    .back {
      margin-left: 1em;
    }
  }

  .action-header {
    width: 15%;
    float: right;
    margin-top: -51px;
    .more-action {
      text-align: right;
      margin-top: -72px;
    }
  }
  .information-banner {
    margin-bottom: 20px;
  }
  .paginate {
    margin: 4rem;
  }

  .Polaris-Page {
    width: 100%;
    max-width: 100%;
    display: inline-block;
    @media (min-width: 1439px) {
      padding: 0;
    }
    .Polaris-Page-Header {
      padding-top: 2rem;
    }
  }
  .Polaris-Card {
    .card-header {
      border-bottom: none;
      padding: 15px;
      @media (max-width: 1199px) and (min-width: 768px) {
        padding: 10px 10px;
      }
      .cross-icon {
        text-align: right;
        .Polaris-Icon {
          cursor: pointer;
          display: inline-block;
          svg {
            fill: var(--p-icon, #637381);
          }
        }
        a {
          outline: none;
        }
      }
      .button-success {
        button {
          background: linear-gradient(to bottom, #28a745, #28a745) !important;
          border-color: #28a745 !important;
          box-shadow: inset 0 1px 0 0 #28a745, 0 1px 0 0 rgba(22, 29, 37, 0.05),
            0 0 0 0 transparent !important;
          .Polaris-Icon {
            display: inline-block;
            height: 1.5rem;
            width: 1.5rem;
            svg {
              margin-top: 2px;
            }
          }
        }
      }
    }
    .editproduct {
      padding: 15px;
    }

    //Tabs
    .Polaris-Tabs__Wrapper {
      .Polaris-Tabs {
        .Polaris-Tabs__TabContainer {
          button {
            &:focus {
              outline: 0;
              box-shadow: none;
            }
            &:active {
              outline: 0;
              box-shadow: none;
            }
          }
        }
      }
    }
    .Polaris-Card__Section {
      padding: 0px;
      .middle-section {
        padding: 2rem;
        .main-wrapper {
          .product-img {
            padding: 20px 20px 20px 20px;
            border: 2px solid #ddd;
            border-radius: 5px;
            @media (max-width: 1199px) and (min-width: 768px) {
              padding: 0px 5px;
            }
            @media (max-width: 1350px) and (min-width: 1200px) {
              padding: 10px 20px 20px 30px;
            }
            .product-img-wrapper {
              width: 100%;
              height: 300px;
              text-align: center;

              @media (max-width: 1350px) and (min-width: 1025px) {
                width: 250px;
                height: 250px;
              }
              @media (max-width: 1024px) and (min-width: 768px) {
                width: 230px;
                height: 230px;
              }
              @media (max-width: 767px) {
                width: 200px;
                height: 200px;
                line-height: 1;
              }
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
          .featured_images {
            padding: 20px 20px 20px 20px;
            border: 2px solid #ddd;
            border-radius: 5px;
            @media (max-width: 1199px) and (min-width: 768px) {
              padding: 10px;
              padding-top: 40px;
            }
            .featured_wrapper {
              text-align: center;
              height: 300px;
              @media (max-width: 1350px) and (min-width: 1200px) {
                height: 250px;
              }
              @media (max-width: 1199px) {
                height: 200px;
              }
              img {
                max-width: 100%;
                height: 100%;
              }
            }
          }
          .product-wrapper {
            .Polaris-Thumbnail--sizeLarge {
              width: 12rem;
            }
            padding: 0px 40px 20px 10px;
            max-height: 360px;
            @media (max-width: 1199px) and (min-width: 768px) {
              padding: 30px 40px 20px 10px;
              max-height: 300px;
            }
            overflow: auto;
            &::-webkit-scrollbar {
              width: 5px;
            }
            &::-webkit-scrollbar-track {
              background: white;
            }
            &::-webkit-scrollbar-thumb {
              background: linear-gradient(to bottom, #6371c7, #5563c1);
              border-radius: 6px;
            }
            .spacing {
              padding: 20px 0px;
            }
            .label-text {
              width: 80px;
              display: inline-block;
              font-weight: 600;
            }
            .label-value {
              text-transform: capitalize;
            }
            //Tags Input Css
            .react-tagsinput {
              background-color: white;
              border: 0.1rem solid var(--p-border, #c4cdd5);
              border-radius: var(--p-border-radius-base, 3px);
              box-shadow: inset 0 1px 0 0 rgba(99, 115, 129, 0.05);
              padding-top: 10px;
              @media (min-width: 1439px) {
                padding-top: 2px;
              }
              .react-tagsinput-tag {
                background-color: var(--p-action-secondary, #dfe3e8);
                border-radius: 3px;
                color: var(--p-text, #212b36);
                border-color: #dfe3e8;
                display: inherit;
                font-size: 1.4rem;
              }
              .react-tagsinput-input {
                color: #000;
                font-size: 1.4rem;
                margin-bottom: 4px;
              }
            }
            .react-tagsinput--focused {
              border: 0.2rem solid #5c6ac4;
              outline: none;
            }
          }
          .description-editor {
            min-height: 250px;
            border: 0.1rem solid var(--p-border-subdued, rgba(63, 63, 68, 0.2));
            margin: 10px 25px;
            .rdw-editor-toolbar {
              border-bottom: 0.1rem solid
                var(--p-border-subdued, rgba(63, 63, 68, 0.2));
            }
            .rdw-editor-main {
              padding: 10px;
            }
            .cke_inner {
              .cke_contents {
                min-height: 300px;
              }
            }
          }
          .thumb-image {
            margin-right: 15px;
            margin-bottom: 20px;
            position: relative;
            padding: 0;
            @media (max-width: 767px) {
              margin-right: 0;
              padding: 0 20px;
            }
            .thumb-wrapper {
              position: relative;
              img {
                height: auto;
                width: 100%;
                box-shadow: var(
                  --p-card-shadow,
                  0 0 0 1px rgba(63, 63, 68, 0.05),
                  0 1px 3px 0 rgba(63, 63, 68, 0.35)
                );
              }
              .Polaris-Choice {
                position: absolute;
                left: 10px;
                top: 10px;
                padding: 0;
                .Polaris-Choice__Control {
                  margin: 0;
                }
              }
            }
          }
        }
      }

      //Bottom Section
      .bottom-section {
        padding: 2rem;
        text-align: right;
        button {
          margin-right: 15px;
          .Polaris-Icon {
            display: inline-block;
            height: 1.5rem;
            width: 1.5rem;
            svg {
              margin-top: 2px;
            }
          }
        }
        .Polaris-Button--success {
          background: linear-gradient(to bottom, #28a745, #28a745) !important;
          border-color: #28a745 !important;
          box-shadow: inset 0 1px 0 0 #28a745, 0 1px 0 0 rgba(22, 29, 37, 0.05),
            0 0 0 0 transparent !important;
        }
      }

      //Set Font Weight Label
      .Polaris-FormLayout__Item {
        .Polaris-Labelled__LabelWrapper {
          .Polaris-Label__Text {
            font-weight: 600;
          }
        }
      }
    }

    //Bottom Section
    .card-footer {
      .bottom-section {
        padding: 2rem;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        button {
          margin-right: 15px;
          .Polaris-Icon {
            display: inline-block;
            height: 1.5rem;
            width: 1.5rem;
            svg {
              margin-top: 2px;
            }
          }
        }
        .Polaris-Button--success {
          background: linear-gradient(to bottom, #28a745, #28a745) !important;
          border-color: #28a745 !important;
          box-shadow: inset 0 1px 0 0 #28a745, 0 1px 0 0 rgba(22, 29, 37, 0.05),
            0 0 0 0 transparent !important;
        }
      }
    }
    .card-header {
      button {
        .Polaris-Icon {
          display: inline-block;
          height: 1.5rem;
          width: 1.5rem;
          svg {
            margin-top: 2px;
          }
        }
      }
    }

    //Table
    .variants-table {
      max-height: 411px;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        background: white;
      }
      &::-webkit-scrollbar-thumb {
        background: linear-gradient(to bottom, #6371c7, #5563c1);
        border-radius: 6px;
      }
    }
    .Polaris-DataTable__Table {
      .Polaris-DataTable__Cell {
        border-bottom: 0.1rem solid
          var(--p-border-subdued, rgba(63, 63, 68, 0.15));
        white-space: normal;
      }
      thead {
        tr {
          th {
            font-weight: 600;
            vertical-align: middle;
          }
        }
      }
      tbody {
        tr {
          th,
          td {
            vertical-align: middle;
          }
          td {
            .action-btn {
              display: flex;
              span {
                button {
                  margin-right: 10px;
                }
              }
            }
          }
          th {
            img {
              height: 80px;
              width: 80px;
              box-shadow: var(
                --p-card-shadow,
                0 0 0 1px rgba(63, 63, 68, 0.05),
                0 1px 3px 0 rgba(63, 63, 68, 0.15)
              );
              padding: 10px;
            }
          }
        }
        tr:last-child {
          .Polaris-DataTable__Cell {
            border-bottom: none;
          }
        }
      }
    }
  }
}
.width-50 {
  width: 50%;
}
.import-wrapper {
  .Polaris-Page {
    .Polaris-Page-Header {
      @media (min-width: 1439px) {
        padding-top: 3.2rem;
      }
    }
  }
}
