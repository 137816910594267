.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #5C6AC4;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
.title_wrpper{
  padding-bottom: 10px;
  .title_heading{
    font-weight: 500;
    min-width: 166px;
    font-size: 14px;
    display: inline-block;
  }
  .title_subheading{
    font-weight: normal;
    font-size: 14px;
    float: right;
  }
}
.title_wrpper:last-child{
  padding-bottom: 0px;
}
  .merchant_product_list{
    position: relative;
    .spiner-merchant{
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .merchant_order_list{
    position: relative;
    .spiner-merchant{
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }