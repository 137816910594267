.dashboard_page {
  .card_item {
    padding: 2.5rem 2rem;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #ddd;
  }
  .icon_img {
    width: 90%;
    opacity: 0.2;
    max-width: 90px;
    width: 55px;
  }
  .card_heading {
    margin-bottom: 3px;
    font-size: 20px;
    color: var(--p-action-primary);
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 130px;
  }
  .card_sub_heading {
    font-size: 14px;
    font-weight: bold;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 116px;
  }
  @media (max-width: 992px) {
    .card_sub_heading {
      text-align: center;
    }
    .card_heading {
      text-align: center;
    }
  }

  .filter_wrapper {
    .form-group {
      .label_wrapper {
        display: inline-block;
      }
      .date_wrapper {
        display: inline-block;
        margin-left: 10px;
        cursor: pointer;
        .input-group {
          position: relative;
          input {
            font-size: 12px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .spiner-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .topProduct_datatable {
    .Polaris-DataTable__Table {
      thead {
        display: none;
      }
    }
  }
  .dashbord_section {
    .Polaris-Card__Header {
      padding-bottom: 15px;
      border-bottom: 1px solid #ddd;
    }
    .form-group {
      padding-bottom: 15px;
    }
    margin-top: 20px;

    .sales_chart {
      .Polaris-Layout__Section {
        .Polaris-Stack {
          flex-wrap: wrap;
        }
      }
    }
  }
  .dashbord_section:nth-last-child(2),
  .dashbord_section:nth-last-child(1) {
    .Polaris-Stack {
      @media (max-width: 767px) {
        margin-left: -6px;
        margin-right: 10px;
      }
    }
  }
  .dashbord_section:nth-last-child(1) {
    @media (max-width: 767px) {
      min-width: -webkit-fill-available;
    }
  }
  .Polaris-DataTable__Table {
    overflow: scroll;
  }
  .text_image_wrapper {
    display: flex;
    .text_wrapper {
      margin: 5px;
    }
  }
}
.daterangepicker {
  .drp-buttons {
    .applyBtn {
      background: linear-gradient(to bottom, #6371c7, #5563c1);
      border-color: #3f4eae;
      box-shadow: inset 0 1px 0 0 #6774c8, 0 1px 0 0 rgba(22, 29, 37, 0.05),
        0 0 0 0 transparent;
      color: white;
      border-width: 1px;
      font-weight: 500;
    }
    .drp-selected {
      display: none;
    }
  }
}

.dashboard-icon{
  vertical-align: baseline !important;
}
